import { config } from './config';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp(config);

export const analytics = firebase.analytics();

export const auth = firebase.auth();
export const fireauth = firebase.auth;
export const provider = new fireauth.GoogleAuthProvider();

export const firestore = firebase.firestore();

