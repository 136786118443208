import React from 'react';
import { Loading } from './LoadingComponent';
import {analytics} from '../firebase/firebase';
import RenderTableRow from './RenderTableRow'; 
import {Redirect} from 'react-router-dom';

function AllRoutes(props) {
    analytics.logEvent('Viewed All Routes');
    var route_group = 1; //starting value     
        
    if (!props.auth.isAuthenticated) //no need to see this page if not logged in
        {
            return(<Redirect to="/home" />)
        }
    else {
        if (props.ferries.isLoading || props.favorites.isLoading) {
            return(
                <div className="container">
                        <Loading />
                </div>
            );
        }
        else if (props.ferries.errMess) {
            return(
                <div className="container">
                    <div className="row mt-5">
                        <h4>{props.ferries.errMess} </h4>
                    </div>
                </div>
            );
        } else {

            const renderFerries = props.ferries.ferry.map((ferry) => {
                if (ferry.nextFerry && ferry.isVisible) { //only show routes with returned ferry times and marked to visible in the DB
                    if (!props.favorites.favorites) {
                        if (ferry.route_group !== route_group) {
                            route_group++;
                            return (
                                <RenderTableRow ferry={ferry} lineBreak={true}/>
                            )
                        } else
                        return (
                            <RenderTableRow ferry={ferry} />
                        );
                    }
                    else 
                    if (props.favorites.favorites.includes(ferry._id)) {
                        if (ferry.route_group !== route_group) {
                            route_group++;
                            return (
                                <RenderTableRow ferry={ferry} lineBreak={true} isFavorite={true}/>
                            )
                        } else
                        return (
                            <RenderTableRow ferry={ferry} isFavorite={true}/>
                        );
                    }
                    else {
                        if (ferry.route_group !== route_group) {
                            route_group++;
                            return (
                                <RenderTableRow ferry={ferry} lineBreak={true}/>
                            )
                        } else
                        return (
                            <RenderTableRow ferry={ferry} />
                        );
                    }          
                }
            })

            return(
                <div className="container py-4">
                    <div className="row">
                        <div class="col-1 px-0"></div>
                        <div class="responsive col-2 px-0 text-center align-bottom">
                            <span class="py-0 my-0 font-weight-bold">From</span>
                        </div>
                        <div class="responsive col-2 px-0 text-center ">
                            <span class="py-0 my-0 font-weight-bold">To</span>
                        </div>
                        <div class="responsive col-2 px-0 text-center ">
                            <span class="py-0 my-0 font-weight-bold">Next Ferry</span>
                        </div>
                        <div class="responsive col-2 px-0 text-center ">
                            <span class="py-0 my-0 font-weight-bold">Leaves </span><span class="py-0 my-0">(mins)</span>
                        </div>
                        <div class="responsive col-2 px-0 text-center">
                            <span class="py-0 my-0 font-weight-bold">Following Ferry</span>
                        </div>
                        <div class="col-1 px-0"></div>
                    </div>
                    <div className="row">
                        <div className="col-12"> 
                            <hr class="py-0 my-0"/>
                        </div>
                    </div>
                    {renderFerries}
                </div>
            );
        }
    }       
}

export default AllRoutes;