import * as ActionTypes from './ActionTypes';
import { auth, firestore, provider } from '../firebase/firebase';
import axios from 'axios';
import * as firebase from 'firebase';
import dayjs from 'dayjs';

export const fetchFerries = (route) => (dispatch) => {
    
    dispatch(ferriesLoading());
    var lastRefresh = dayjs().format('MMM-D HH:mm:ss')
    return axios.get('/getFerry')
    .then(res => {
        let ferries = null;
        ferries = res.data;
        return ferries;
    })
    .then(ferries => dispatch(addFerries(ferries, lastRefresh)))
    .catch(error => dispatch(ferriesFailed(error.message)));
}

export const ferriesLoading = () => ({
    type: ActionTypes.FERRIES_LOADING
});

export const ferriesFailed = (errmess) => ({
    type: ActionTypes.FERRIES_FAILED,
    payload: errmess
});

export const addFerries = (ferries, lastRefresh) => ({
    type: ActionTypes.ADD_FERRIES,
    payload: {ferries, lastRefresh}
});

//////////////////////////////////

export const postFavorite = (ferryId) => (dispatch) => {

    if (!auth.currentUser) {
        console.log('No user logged in!');
        return;
    }

    var user = auth.currentUser;

    return firestore.collection('favorites').where('user', '==', user.uid).get()
    .then(snapshot => {
        if (snapshot.empty) {
            firestore.collection('favorites').doc().set({user: user.uid, favferries: firebase.firestore.FieldValue.arrayUnion(ferryId)})
            dispatch(fetchFavorites())
        }
        else {
            snapshot.forEach(doc => { //user has no favorites document, so create one
                if (doc.exists) { //need to update their favorites doc with the new favorite
                    firestore.collection('favorites').doc(doc.id).update({favferries: firebase.firestore.FieldValue.arrayUnion(ferryId)})
                    .then(() => {
                        firestore.collection('favorites').doc(doc.id).get()
                            .then(docRef => {
                                if (docRef.exists) {
                                    dispatch(fetchFavorites())
                                } else {
                                    // doc.data() will be undefined in this case
                                    console.log("No such document!");
                                }
                            });
                    })
                }
            })
        }
    })
    .catch(error => dispatch(favoritesFailed(error.message)));
}

export const deleteFavorite = (ferryId) => (dispatch) => {

    if (!auth.currentUser) {
        console.log('No user logged in!');
        return;
    }

    var user = auth.currentUser;

    return firestore.collection('favorites').where('user', '==', user.uid).get()
    .then(snapshot => {
        snapshot.forEach(doc => {
            if (doc.exists) { //need to update their favorites doc with the new favorite
                firestore.collection('favorites').doc(doc.id).update({favferries: firebase.firestore.FieldValue.arrayRemove(ferryId)})
                .then(() => {
                    firestore.collection('favorites').doc(doc.id).get()
                        .then(docRef => {
                            if (docRef.exists) {
                                dispatch(fetchFavorites())
                            } else {
                                // doc.data() will be undefined in this case
                                console.log("No such document!");
                            }
                        });
                })
            }
        })
        
    })
    .catch(error => dispatch(favoritesFailed(error.message)));
};

export const fetchFavorites = () => (dispatch) => {

    if (!auth.currentUser) {
        console.log('No user logged in!');
        return;
    }

    var user = auth.currentUser;

    dispatch(favoritesLoading(true));

    return firestore.collection('favorites').where('user', '==', user.uid).get()
    .then(snapshot => {
        var data = {favferries: null};
        
        snapshot.forEach(doc => {
            if (doc.exists)
            data = doc.data();
        })
     
        return data.favferries;
    })
    .then(favorites => dispatch(addFavorites(favorites)))
    .catch(error => dispatch(favoritesFailed(error.message)));
}

export const favoritesLoading = () => ({
    type: ActionTypes.FAVORITES_LOADING
});

export const favoritesFailed = (errmess) => ({
    type: ActionTypes.FAVORITES_FAILED,
    payload: errmess
});

export const addFavorites = (favorites) => ({
    type: ActionTypes.ADD_FAVORITES,
    payload: favorites
});

////////////////////////////////////////////////////////////////////////////


export const checkLogin = () => (dispatch) => {
    auth.onAuthStateChanged((user) => {
        if (user) {
          dispatch(receiveLogin(user));
          dispatch(fetchFerries());
          dispatch(fetchFavorites());
        }
       else { 
        dispatch(fetchFerries());
       }
      }
    )
}

export const googleLogin = () => (dispatch) => {
        auth.signInWithRedirect(provider)
            .then((result) => {
                var user = result.user;
                //localStorage.setItem('user', JSON.stringify(user));
                // Dispatch the success action
                dispatch(receiveLogin(user));
                dispatch(fetchFavorites());
            })
            .catch((error) => {
                dispatch(loginError(error.message));
            });
}

  
export const receiveLogin = (user) => {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        user
    }
}
  
export const loginError = (message) => {
    return {
        type: ActionTypes.LOGIN_FAILURE,
        message
    }
}

export const requestLogout = () => {
    return {
      type: ActionTypes.LOGOUT_REQUEST
    }
}
  
export const receiveLogout = () => {
    return {
      type: ActionTypes.LOGOUT_SUCCESS
    }
}

// Logs the user out
export const logoutUser = () => (dispatch) => {
    dispatch(requestLogout())
    auth.signOut().then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
    //localStorage.removeItem('user');
    dispatch(favoritesFailed("Error 401: Unauthorized"));
    dispatch(receiveLogout())
}

////////////////////////////////////////////////

export const fetchConfig = () => (dispatch) => {

    dispatch(configLoading(true));

    return firestore.collection('config').get()
    .then(snapshot => {
        var config = null;
        
        snapshot.forEach(doc => {
            if (doc.exists)
            config = doc.data();
        })
     
        return config;
    })
    .then(config => dispatch(addConfig(config)))
    .catch(error => dispatch(configFailed(error.message)));
}

export const configLoading = () => ({
    type: ActionTypes.CONFIG_LOADING
});

export const configFailed = (errmess) => ({
    type: ActionTypes.CONFIG_FAILED,
    payload: errmess
});

export const addConfig = (config) => ({
    type: ActionTypes.ADD_CONFIG,
    payload: config
});


/////////////////////////////////////////////////
