import * as ActionTypes from './ActionTypes';

export const Config = (state = {
        isLoading: true,
        errMess: null,
        typhoon_toggle: null,
        google_maps_toggle: null
    }, action) => {
    switch(action.type) {
        case ActionTypes.ADD_CONFIG:
            return {...state, isLoading: false, errMess: null, typhoon_toggle: action.payload.typhoon_toggle};

        case ActionTypes.CONFIG_LOADING:
            return {...state, isLoading: true, errMess: null, typhoon_toggle: null};

        case ActionTypes.CONFIG_FAILED:
            return {...state, isLoading: false, errMess: action.payload, typhoon_toggle: null};

        default:
            return state;
    }
}