import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';


function RenderTableRow({ferry, lineBreak, isFavorite}) {
        
        var nextFerry_ampm, followingFerry_ampm = '';
        
        var nextFerry_hour = "--:--";
        if (ferry.nextFerry.nextFerryTime) 
            nextFerry_hour = ferry.nextFerry.nextFerryTime.split(":");
        
        var followingFerry_hour = "--:--";
        if (ferry.nextFerry.followingFerryTime) 
            followingFerry_hour = ferry.nextFerry.followingFerryTime.split(":");

        var nextFerryHour = nextFerry_hour[0];
        var nextFerryMinute = nextFerry_hour[1];
        var followingFerryHour = followingFerry_hour[0];
        var followingFerryMinute = followingFerry_hour[1];



        if (nextFerryHour < 12) { //00:00-11:59
            nextFerry_ampm = 'am';
            if (nextFerryHour < 10)
                nextFerryHour = nextFerryHour.substring(1);
         } 
        else if (nextFerryHour >=12) {
            nextFerry_ampm = 'pm'; //12:00-23:59
            if (nextFerryHour > 12)
            nextFerryHour = nextFerryHour - 12;
        }
        
        if (followingFerryHour < 12) {
            followingFerry_ampm = 'am';
            if (followingFerryHour < 10)
                followingFerryHour = followingFerryHour.substring(1);
        }
        else if (followingFerryHour >= 12) {
            followingFerry_ampm = 'pm';
            if (followingFerryHour > 12)
            followingFerryHour = followingFerryHour - 12;
        }

        if (nextFerryHour === '0') 
            nextFerryHour = '12';

        if (followingFerryHour === '0') 
            followingFerryHour = '12';

        
        let remainingTime = '';
        let font = 'text-info ml-1';
        let bgColorClass = "";
        if (ferry.timeRemaining.difference < 0) {remainingTime = "--"}
        else if (ferry.timeRemaining.difference === 0) {bgColorClass = "bg-danger"; font = "text-white ml-1"; remainingTime = "< 1"}
        else if (ferry.timeRemaining.difference === 1) {bgColorClass = "bg-danger"; font = "text-white ml-1"; remainingTime = remainingTime.concat(ferry.timeRemaining.difference)}
        else if (ferry.timeRemaining.difference <= 10) {bgColorClass = "bg-warning"; font = "text-black ml-1"; remainingTime = remainingTime.concat(ferry.timeRemaining.difference)}
        else if (ferry.timeRemaining.difference > 60) {font = "text-info text-center pl-1"; remainingTime = ">1hr"}
        else remainingTime = remainingTime.concat(ferry.timeRemaining.difference);
        
        bgColorClass = bgColorClass.concat(" text-center responsive rounded px-1 mt-1");
        
        var emptyRow = '';

        if (lineBreak) { //then make a constant that returns just a line break
            emptyRow = <div class="row align-items-start" > <div class="col-12 py-1" /></div>
            }
        else {
            emptyRow = ''
        }

        var showheart = '';

        if (isFavorite) { //then make a constant that returns just a line break
            showheart = <span className="favoriteHeartsm fa fa-heart"></span>
            }
        else {
            showheart = ''
        }
            

        return (
            <Fragment>
                {emptyRow}
                <Link to={`/route/${ferry._id}`} class="nounderline" >

                    <div class="row my-0 py-0 align-items-start hoverDiv" >
                        
                        <div class="col-1">
                           {showheart} 
                        </div>
                        <div class="col-2 px-0" >
                            <div class="responsive py-1">
                                <p class="text-center py-0 my-0">{ferry.origin}&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-2 px-0" >
                            <div class="responsive py-1">
                                <p class="text-center py-0 my-0">{ferry.destination}</p>
                            </div>
                        </div>
                        <div class="col-2 px-0" >
                            <div class="responsive py-1">
                                <p class="text-center py-0 my-0">{nextFerryHour}:{nextFerryMinute}{nextFerry_ampm}</p>
                            </div>
                        </div>
                        <div class="col-2 px-0">
                            <div class={bgColorClass}>
                                <span className={font}>{remainingTime}</span>
                            </div>
                        </div>
                        <div class="col-2 px-0" >
                            <div class="responsive py-1">
                                <p class="text-center py-0 my-0">{followingFerryHour}:{followingFerryMinute}{followingFerry_ampm}</p>
                            </div>
                        </div>
                        <div class="col-1 px-0"></div>
                    </div>
                </Link>
                
            </Fragment>
        )    
    
}

export default RenderTableRow;