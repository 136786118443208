import React from 'react';

export const Loading = () => {
    return(
        <div className="row mt-5">
            <div className="col-12 ml-4">
                <span className="fa fa-life-ring fa-spin fa-3x fa-fw text-danger"></span>
                <p><strong className="mt-2 text-primary bold">Loading . . .</strong></p>
            </div>
        </div>
    );
};