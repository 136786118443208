import React, {Fragment, useEffect, useState} from 'react';
import { Loading } from './LoadingComponent';
import RenderFerry from './RenderFerry';
import RenderVideo from './RenderVideo';
import {analytics} from '../firebase/firebase';
import { config } from '../firebase/config';
import { GoogleApiWrapper } from 'google-maps-react';
import '../map.css';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

const API_KEY = config.apiKey;

const LogGAEvent = ({ferry}) => {
    analytics.logEvent(ferry.route)
    return null
}

const GetWdSchedule = React.memo((props) => {
    const [schedule, setSchedule] = useState([]);
    const fetchSchedule = async () => {
        const apiCall = await axios.get(`/getSchedule/${props.props.route._id}`);
        const data = await apiCall.data;
        setSchedule(data.weekday);
    }
    useEffect(() => {
        fetchSchedule();
    }, []
    )
    let nextFerry = props.props.route.nextFerry.nextFerryTime;
    if (schedule && schedule.length > 0) {
        return schedule.map((time) => {
            if ((time === nextFerry) && (props.props.route.today === 'weekday')) { 
                return(<td class="table-primary">{time}</td>)
            }
                else return(<td>{time}</td>)
        }
    )}
})

const GetSatSchedule = React.memo((props) => {
    const [schedule, setSchedule] = useState([]);
    const fetchSchedule = async () => {
        const apiCall = await axios.get(`/getSchedule/${props.props.route._id}`);
        const data = await apiCall.data;
        setSchedule(data.saturday);
    }
    useEffect(() => {
        fetchSchedule();
    }, []
    )
    let nextFerry = props.props.route.nextFerry.nextFerryTime;
    if (schedule && schedule.length > 0) {
        return schedule.map((time) => {
            if ((time === nextFerry) && (props.props.route.today === 'saturday')) { 
                return(<td class="table-primary">{time}</td>)
            }
                else return(<td>{time}</td>)
        }
    )}
})

const GetSunSchedule = React.memo((props) => {
    const [schedule, setSchedule] = useState([]);
    const fetchSchedule = async () => {
        const apiCall = await axios.get(`/getSchedule/${props.props.route._id}`);
        const data = await apiCall.data;
        setSchedule(data.sunday_hol);
    }
    useEffect(() => {
        fetchSchedule();
    }, []
    )
    let nextFerry = props.props.route.nextFerry.nextFerryTime;
    if (schedule && schedule.length > 0) {
        return schedule.map((time) => {
            if ((time === nextFerry) && (props.props.route.today === 'sunday_hol')) { 
                return(<td class="table-primary">{time}</td>)
            }
                else return(<td>{time}</td>)
        }
    )}
})


const GetLocation = React.memo((props) => {
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                var latitude = position.coords.latitude;
                var longitude = position.coords.longitude;

                if (position.coords.accuracy < 200 && latitude < 22.56 && latitude > 22.15 && longitude > 113.81 && longitude < 114.43) {
                    //lat/long figures are generally a boundary around HK
                    //change accuracy back to 200 for mobile device accuracy

                    const {google} = props.props
                    const service = new google.maps.DistanceMatrixService();
                    const origins = new google.maps.LatLng(latitude, longitude)
                    const destinations = new google.maps.LatLng(props.props.route.latitude, props.props.route.longitude)
                    service.getDistanceMatrix(
                        {
                            origins: [origins],
                            destinations: [destinations],
                            travelMode: 'WALKING'}, 
                            (res, status) => {
                                if (status !== "OK") {
                                    console.log('error: ', status)
                                } else {
                                    var distanceObj = {
                                        distance: res.rows[0].elements[0].distance.text, 
                                        duration: res.rows[0].elements[0].duration.text,
                                        accuracy: position.coords.accuracy
                                    }
                                }
                                const outputDiv = document.getElementById("output");
                                outputDiv.innerHTML = `According to <a href="https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${props.props.route.latitude},${props.props.route.longitude}&travelmode=walking" target="_blank" rel="noopener noreferrer">Google Maps</a>, walking to ${props.props.route.origin} pier will take you <strong>${distanceObj.duration}</strong> (${distanceObj.distance})`;
                            }
                    )
                }
            })
            
        } else { 
            console.error("Geolocation is not supported by this browser.")
        }
        return () => {
            
        }
    }, [])
    return null
    
})

const RouteDetail = React.memo((props) => {

    if (props.isLoading) {
        return(
            <div className="container">
                    <Loading />
            </div>
        );
    }
    else if (props.errMess) {
        return(
            <div className="container">
                <div className="row mt-5">
                    <h4>{props.errMess}</h4>
                </div>
            </div>
        );
    }
    else if (props.route != null && props.route.isVisible) {
        let day = "0"; //weekday (eg first accordian)
        
        switch (props.route.today) { //sets default accordian activekey
            case "sunday_hol":
                day = "2";
                break;
            case "saturday":
                day = "1";
                break;
        }

        return (
            <div className="container">
                <div className="row">
                    <GetLocation props={props}/>
                    <div className="mt-5">
                        <div class="alert-info ml-3">
                                <p id="output" class="mx-2"></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                        <LogGAEvent ferry={props.route} />
                        <RenderFerry ferry={props.route} auth={props.auth} isFavorite={props.isFavorite} postFavorite={props.postFavorite} deleteFavorite={props.deleteFavorite} googleLogin={props.googleLogin}/>
                        <RenderVideo ferry={props.route} />
                </div>
                <div className="row pt-2 table-responsive-sm">
                    <Accordion responsive defaultActiveKey={day}>
                        <Card>
                            <Card.Header class="px-1 py-0 text-white bg-secondary">
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                    Weekday
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Table responsive bordered size="sm">
                                    <tbody class="text-center">
                                        <tr>
                                            <GetWdSchedule props={props}/>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header class="px-1 py-0 text-white bg-secondary">
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                                    Saturday
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                            <Table responsive bordered size="sm">
                                    <tbody class="text-center">
                                        <tr>
                                            <GetSatSchedule props={props}/>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header class="px-1 py-0 text-white bg-secondary">
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                                    Sunday/Holiday
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                            <Table responsive bordered size="sm">
                                    <tbody class="text-center">
                                        <tr>
                                            <GetSunSchedule props={props}/>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>

                <div className="row pt-2">
                    <h6><a href={props.route.official_url} target="_blank" rel="noopener noreferrer">&nbsp;Click here</a> to view official ferry information</h6>
                </div>
            </div>
        );
    }     
        
    else
        return(
            <div></div>
        );
})

const LoadingContainer = (props) => (
    <div className="container">
            <Loading />
    </div>
  )

export default GoogleApiWrapper({apiKey: (API_KEY), LoadingContainer: LoadingContainer})(RouteDetail)