import React, { Component } from 'react';
import Main from './components/MainComponent';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';
import axios from 'axios';

import ReactDOMServer from 'react-dom/server';
import PullToRefresh from 'pulltorefreshjs';

const store = ConfigureStore();

axios.defaults.baseURL = 'https://asia-east2-myhkferry.cloudfunctions.net/api'; //prod
//axios.defaults.baseURL = 'http://localhost:5002/myhkferry/asia-east2/api'; //dev

class App extends Component {

  componentDidMount()
  {
      PullToRefresh.init({
          mainElement: 'body',
          onRefresh() {
              window.location.reload();
          },
          iconArrow: ReactDOMServer.renderToString(
            <span className="fa fa-life-ring fa-spin fa-2x fa-fw text-danger"></span>
          ),
          iconRefreshing: ReactDOMServer.renderToString(
            <span className="fa fa-life-ring fa-spin fa-2x fa-fw text-danger"></span>
          ),
      });
  }

  componentWillUnmount()
  {
      // Don't forget to destroy all instances on unmout
      // or you will get some glitches.
      PullToRefresh.destroyAll();
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <Main />
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
