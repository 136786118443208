import React from 'react';
import {analytics} from '../firebase/firebase';

const About = () => {
    analytics.logEvent('Viewed About Page');
    return(
        <div>
            <a name="top" />
            <div className="bgimg-1">
                
                        <div class="abtcaption">
                            <span class="border">About My HK Ferry</span>
                            <div class="abttoc">
                                <span class="border"><a href="#intro" class="abtanchor">Intro</a> | <a href="#purpose" class="abtanchor">Purpose</a> | <a href="#features" class="abtanchor">Features</a> | <a href="#tech" class="abtanchor">Technical</a></span>
                            </div>
                        </div>
              
            </div>

            <a name="intro" />
            <div className="mt-1 abtbody">
                <h3 className="abttitle">Introduction</h3>
                <span>
                    <p>Ferries are a large part of the fabric of Hong Kong travel.  They’re inexpensive, almost always on time, and offer some of the best views of the city.  When I recently moved to a new district with a nearby ferry pier, I knew I would be a frequent customer but wasn't always sure when the next one departed.  Is it peak time?  Are they on the holiday schedule?  Is it Saturday!?</p>
                    <p>Hong Kong’s ferry system carries over 40 million passengers per year and consists of 19 routes.  But since those routes are operated across 12 distinct companies, it is sometimes a challenge to get a clear and consistent view of their schedules.</p>
                    <p>While there is an official website listing all departure times, it's not mobile-friendly, difficult to navigate, and it won't help you if you forget that today is a holiday.  There are other apps out there but I’ve found them to be a bit too busy.  I wanted a simple and clean site that gave me just the right amount of information I was looking for.</p>
                </span>
            </div>

            <div className="bgimg-2">      
                
            </div>
            
            <a name="purpose" />
            <div className="mt-1 abtbody">
                <h3 className="abttitle">Purpose</h3>
                <span>
                    <p>The purpose of the site is to answer <strong>two basic questions:</strong> what time does the next ferry leave and, if I can’t make it, when does the following ferry leave?</p>
                    <p>It is intended for the casual ferry passenger who knows which ferry to take but hasn't memorized the schedule.  </p>
                    <p>This site is not intended to serve as a route planner or a one-stop-shop for all ferry information.  It doesn’t take into account things like Fast vs. Ordinary service or whether freight service is offered.  For these details including travel durations, fares, etc, a link to the official website is provided on each route page.</p>
                    <p>It also does not include the famous <a href="http://www.starferry.com.hk/en/home" target="_blank" rel="noopener noreferrer">Star Ferry</a> between Central/Tsim Sha Tsui/Wan Chai which departs frequently or the several smaller <a href="https://www.td.gov.hk/en/transport_in_hong_kong/public_transport/ferries/kaito_services_map/service_details/index.html#" target="_blank" rel="noopener noreferrer">kaito routes</a> which have varying schedules.</p>
                </span>
            </div>

            <div className="bgimg-3">      
                
            </div>
            <a name="features" />
            <div className="mt-1 abtbody">
                <h3 className="abttitle">Features</h3>
                <span>
                    <p>I intended to keep this site extremely intuitive, but let me explain a few of the key features:</p>
                    <p><strong>Main page:</strong> This shows all of the ferry routes running today, their origin, destination, the departure time of the next ferry, how many minutes before that ferry departs, and the departure time for the subsequent ferry.  Sometimes the next or subsequent ferry will be tomorrow.  If that ferry does not run tomorrow, you will see '--' as a departure time.</p>
                    <img src="/images/distance.jpg" class="fluid img-thumbnail float-left my-0 mr-4" alt="Google Maps" />
                    <p><strong>Ferry Details:</strong> If you click on a ferry route, you will be brought to the Ferry Detail page which shows you the same information, allows you to add the route to your Favorites, displays a link to the route's official website, and it may even show a video of the ferry ride.                      
                    <br/>Additionally, if you are in Hong Kong and your location is detected accurately, you will see a message from Google Maps informing you how long it will take to walk to your departing ferry pier.  You can click on this to open Google Maps and obtain full directions.</p>                
                    <p><strong>Favorites:</strong> If you login, you will be brought to this page showing a list of the ferry routes that you have selected as your Favorites.  Selecting Favorites helps to simplify your view by only showing routes that you care about.  This will be your default page next time you return - until you log out.  You can always click the 'View All Routes' button at the top to see a listing of all ferry routes, choose additional favorites, etc.</p>
                    <p><strong>Color Codes:</strong> The remaining minutes until departure will be shaded in <span class="text-warning">Yellow</span> if the next ferry leaves in 10 minutes or less.  <span class="text-danger">Red</span> if it leaves in 1 minute or less.  If the ferry leaves tomorrow, you will see '--'.</p>
                    <p><strong>Other features:</strong> Since you may be viewing a cached version of the site, you will see a timestamp at the bottom of each page showing when the data was last refreshed.  You can hit refresh on your browser, or pull down to refresh on mobile devices, to obtain the latest departure data.</p>
                    <p> Departure times will automatically adjust for <a href="https://www.gov.hk/en/about/abouthk/holiday/" target="_blank" rel="noopener noreferrer">public holidays</a>.  <br/>While I'm not planning to turn this into a mobile app, you can operate it as a mobile app by using the 'Add to Home Screen' feature on iOS/Safari devices, or Android/Chrome.</p>
                </span>
            </div>

            <div className="bgimg-4">      
        
            </div>
            <a name="tech" />
            <div className="mt-1 abtbody">
                <h3 className="abttitle">Technical</h3>
                <span>
                    <p>Beyond solving an everyday problem, another goal of mine was to learn some new development techniques in my spare time. Although I've spent my entire career in technology, I haven’t done any personal coding since the 12th grade.  A lot has changed in those 20+ years and I was anxious to learn new techniques and try something hands-on.
                    <br/><br/>Here are some of the technical details behind this project:
                    </p>
                    <p><strong>Language:</strong> The site is written in JavaScript using the <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React 16</a> framework, utilizing 12 components to manage the single-page application.  Though it was overkill for a simple site like this, I used <a href="https://react-redux.js.org/" target="_blank" rel="noopener noreferrer">React Redux 7</a> to manage application state in a scalable manner. Redux can be complex but it's an extremely elegant and efficient architecture so it was worth the time to learn.</p>
                    <p><strong>Design:</strong> To ensure a mobile-friendly responsive design, <a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">Bootstrap 4</a> is used with occasional components from the <a href="https://reactstrap.github.io/" target="_blank" rel="noopener noreferrer">Reactstrap 5</a> library and <a href="https://fontawesome.com/" target="_blank" rel="noopener noreferrer">FontAwesome 5</a>.</p>
                    <p><strong>Hosting:</strong> <a href="https://firebase.google.com/" target="_blank" rel="noopener noreferrer">Google Firebase</a> provides the primary backend/hosting services. It was extremely simple to set up with great documentation.</p>
                    <p><strong>Database:</strong> To store all ferry routes, timetables, and user's favorites, I'm using the Firebase-provided <a href="https://firebase.google.com/docs/firestore" target="_blank" rel="noopener noreferrer">Firestore</a> which is a NoSQL JSON-based datastore.</p>
                    <p><strong>Authentication:</strong> I decided to integrate with Google as a "social sign-on" <a href="https://firebase.google.com/docs/auth" target="_blank" rel="noopener noreferrer">authentication</a> service since it's fairly ubiquitous in Hong Kong and I wanted to avoid managing my own user store.  I may consider expanding to other services in the future if there's a need.</p>
                    <p><strong>Server-side:</strong> I did write one <a href="https://firebase.google.com/docs/functions" target="_blank" rel="noopener noreferrer">Node.js function</a>, also hosted in Firebase, that manages the majority of the work to pull records from the database and provide a REST API to the front-end.  This is queried from my front-end with help from the <a href="https://www.npmjs.com/package/axios" target="_blank" rel="noopener noreferrer">Axios</a> library.</p>
                    <p><strong>Distance calculation:</strong> To show how long it will take to walk to the departing ferry, I used the Google Maps API, specifically, the <a href="https://developers.google.com/maps/documentation/distance-matrix/overview" target="_blank" rel="noopener noreferrer">Distance Matrix</a> service.  This only triggers for users in Hong Kong whose location can be accurately determined.  For this I use the getCurrentPosition method under the <a href="https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API" target="_blank" rel="noopener noreferrer">Geolocation API</a> which is now supported on nearly all current browsers and devices.</p>
                    <p><strong>Analytics:</strong> The whole site is connected into <a href="https://firebase.google.com/docs/analytics" target="_blank" rel="noopener noreferrer">Google Analytics</a>, also standard with Google Firebase.</p>
                    <p><strong>Other pieces:</strong> <a href="https://www.npmjs.com/" target="_blank" rel="noopener noreferrer">NPM</a> was used for managing packages and build, <a href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer">Visual Studio Code</a> for an IDE, and <a href="https://github.com/" target="_blank" rel="noopener noreferrer">Github</a> for versioning/source control.</p>
                    <p><strong>Maintenance:</strong> One of my main objectives in developing this site was to keep maintenance to a minimum.  Every aspect of this site, well, except for this page, is dynamically driven from the database.  Holidays are updated once per year, ferry schedules rarely change, and if a new ferry route is added, it will appear once it is added to the database and a feature flag is enabled.  I am also using <a href="https://visualping.io/" target="_blank" rel="noopener noreferrer">Visualping.io</a> to alert me in case there are any changes on the official ferry schedule website.</p>
                </span>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="text-center">
                        <iframe width="320" height="185" src="https://www.youtube.com/embed/W1A2EqiX3AI?autoplay=1&mute=1&loop=1&modestbranding=1&controls=1&playlist=W1A2EqiX3AI" frameborder="0" ></iframe>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About;