import React, {Component} from 'react';
import { Card, CardText, CardBody, CardTitle, Button } from 'reactstrap';


class RenderFerry extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    }

    handleGoogleLogin(event) {
        this.props.googleLogin();
        event.preventDefault();
    }
    
    render() {
        var nextFerry_hour = "--:--";
        if (this.props.ferry.nextFerry.nextFerryTime) 
            nextFerry_hour = this.props.ferry.nextFerry.nextFerryTime.split(":");
        
        var followingFerry_hour = "--:--";
        if (this.props.ferry.nextFerry.followingFerryTime) 
            followingFerry_hour = this.props.ferry.nextFerry.followingFerryTime.split(":");



        var nextFerry_ampm, followingFerry_ampm = '';
        var nextFerryHour = nextFerry_hour[0];
        var nextFerryMinute = nextFerry_hour[1];
        var followingFerryHour = followingFerry_hour[0];
        var followingFerryMinute = followingFerry_hour[1];

        if (nextFerry_hour[0] < 12) nextFerry_ampm = 'am' //00:00-11:59
        else {
            nextFerry_ampm = 'pm'; //12:00-23:59
            if (nextFerry_hour[0] > 12)
            nextFerryHour = nextFerryHour - 12;
        }
        
        if (followingFerry_hour[0] < 12) followingFerry_ampm = 'am' 
        else {
            followingFerry_ampm = 'pm';
            if (followingFerry_hour[0] > 12)
            followingFerryHour = followingFerryHour - 12;
        }

        if (nextFerryHour === '00') 
            nextFerryHour = '12';

        if (followingFerryHour === '00') 
            followingFerryHour = '12';
        
        let remainingTime = '';
        let font = 'text-primary';
        let card_border = 'primary';
        if (this.props.ferry.timeRemaining.difference < 0) remainingTime = "It leaves tomorrow, take your time"
        else if (this.props.ferry.timeRemaining.difference === 0) {card_border = "danger"; font = "text-danger"; remainingTime = "Hurry up, the ferry is about to leave"}
        else if (this.props.ferry.timeRemaining.difference === 1) {card_border = "danger"; font = "text-danger"; remainingTime = "It leaves in ".concat(this.props.ferry.timeRemaining.difference).concat(" minute")}
        else if (this.props.ferry.timeRemaining.difference <= 10) {card_border = "warning"; font = "text-warning"; remainingTime = "It leaves in ".concat(this.props.ferry.timeRemaining.difference).concat(" minutes")}
        else if (this.props.ferry.timeRemaining.difference > 60) {card_border = "success"; font = "text-success"; remainingTime = "It leaves in over an hour"}
        else remainingTime = "It leaves in ".concat(this.props.ferry.timeRemaining.difference).concat(" minutes");
        
        if (this.props.auth.isAuthenticated) {
            return(
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <Card body outline color={card_border} className="ferryCard">
                            
                            <CardTitle className="text-center">{this.props.ferry.origin} to {this.props.ferry.destination}</CardTitle>
                            <CardBody className="pb-4">
                                <CardText>The next ferry to {this.props.ferry.destination} is: {nextFerryHour}:{nextFerryMinute}{nextFerry_ampm}</CardText>
                                <CardText className={font}>{remainingTime}</CardText>
                                <CardText>The following ferry to {this.props.ferry.destination} is: {followingFerryHour}:{followingFerryMinute}{followingFerry_ampm}</CardText>
                            </CardBody>
                            
                                <Button className="favoriteButton" color="link" onClick={() => this.props.isFavorite ? this.props.deleteFavorite(this.props.ferry._id) : this.props.postFavorite(this.props.ferry._id)}>
                                    {this.props.isFavorite ?
                                        <span className="favoriteHeart fa fa-heart"></span>
                                        : 
                                        <span className="favoriteHeart far fa-heart"></span>
                                    }
                                </Button>
                            
                        </Card>
                </div>
            )
        } else {
            return(
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-5">
                        <Card body outline color={card_border} >
                            <CardTitle className="text-center">{this.props.ferry.origin} to {this.props.ferry.destination}</CardTitle>
                            <CardBody >
                                <CardText>The next ferry to {this.props.ferry.destination} is: {nextFerryHour}:{nextFerryMinute}{nextFerry_ampm}</CardText>
                                <CardText className={font}>{remainingTime}</CardText>
                                <CardText>The following ferry to {this.props.ferry.destination} is: {followingFerryHour}:{followingFerryMinute}{followingFerry_ampm}</CardText>
                            </CardBody>
                            <Button className="favoriteButton" color="link"  onClick={this.handleGoogleLogin}>
                                <span className="favoriteHeart far fa-heart"></span>
                            </Button>
                        </Card>
                </div>
            )
        }
    }
}

export default RenderFerry;