import React, { Component } from 'react';
import Home from './HomeComponent';
import RouteDetail from './RouteDetailComponent';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchFerries, logoutUser, fetchFavorites, googleLogin, postFavorite, deleteFavorite, checkLogin, fetchConfig } from '../redux/ActionCreators';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Favorites from './FavoritesComponent';
import AllRoutes from './AllRoutesComponent';
import About from './AboutComponent';

const mapStateToProps = state => {
    return {
      ferry: state.ferries,
      favorites: state.favorites,
      auth: state.auth,
      typhoon_toggle: state.config.typhoon_toggle
    }
}

const mapDispatchToProps = (dispatch) => ({
  fetchFerries: () => dispatch(fetchFerries()),
  logoutUser: () => dispatch(logoutUser()),
  fetchFavorites: () => dispatch(fetchFavorites()),
  googleLogin: () => dispatch(googleLogin()),
  postFavorite: (ferryId) => dispatch(postFavorite(ferryId)),
  deleteFavorite: (ferryId) => dispatch(deleteFavorite(ferryId)),
  checkLogin: () => dispatch(checkLogin()),
  fetchConfig: () => dispatch(fetchConfig())
});

class Main extends Component {

  componentDidMount() {
    this.props.checkLogin();
    this.props.fetchConfig();
  }

  render() {

    const HomePage = () => {
      return(
        (this.props.auth.isAuthenticated && !this.props.favorites.isLoading)
        ?
        <Favorites ferries={this.props.ferry} 
              favorites={this.props.favorites}
              auth={this.props.auth}
        />
        :
        <Home ferries={this.props.ferry} 
              favorites={false}
              auth={this.props.auth}
        />
      );
    }
    
    const RouteWithId = ({match}) => {
      return(
        (this.props.auth.isAuthenticated && this.props.favorites.favorites)
        ?
        <RouteDetail route={this.props.ferry.ferry.filter((ferry) => ferry._id === match.params.routeId)[0]}
          isLoading = {this.props.ferry.isLoading}
          errMess={this.props.ferry.errMess}
          auth={this.props.auth}
          isFavorite={this.props.favorites.favorites.some((favferry) => favferry === match.params.routeId)}
          postFavorite={this.props.postFavorite}
          deleteFavorite={this.props.deleteFavorite}
          googleLogin={this.props.googleLogin}
          fetchSchedule={this.props.fetchSchedule}
          />
          :
          <RouteDetail route={this.props.ferry.ferry.filter((ferry) => ferry._id === match.params.routeId)[0]}
          isLoading = {this.props.ferry.isLoading}
          errMess={this.props.ferry.errMess}
          auth={this.props.auth}
          isFavorite={false}
          postFavorite={this.props.postFavorite}
          deleteFavorite={this.props.deleteFavorite}
          googleLogin={this.props.googleLogin}
          fetchSchedule={this.props.fetchSchedule}
          />
      );
    }

    return (
      <div>
        <Header auth={this.props.auth} 
          loginUser={this.props.loginUser} 
          logoutUser={this.props.logoutUser}
          googleLogin={this.props.googleLogin}
          typhoon_toggle={this.props.typhoon_toggle}/>
        <TransitionGroup>
          <CSSTransition key={this.props.location.key} classNames="page" timeout={300}>
            <Switch>
            <Route path="/home" component={HomePage} />
            <Route path="/route/:routeId" component={RouteWithId} />
            <Route path="/allroutes" component={() => <AllRoutes ferries={this.props.ferry} favorites={this.props.favorites} auth={this.props.auth}/>} />
            <Route path="/about" component={() => <About /> }/>
            <Redirect to="/home" />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <Footer lastRefresh={this.props.ferry}/>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));