import React from 'react';

function Footer(props) {

    if (props.lastRefresh.isLoading) {
        return(
            <div className="container">      
            </div>
        );
    }
    else 
    {
        return( 
            <div class="container">
                <div class="row justify-content-md-center mt-2">
                    <div class="responsive col-12">
                        <p><em>Data last refreshed: {props.lastRefresh.lastRefresh}</em></p>
                        <hr />
                        <p className="h7">© {new Date().getFullYear()}, <a href='/about#top'>About</a> | <a href="mailto:myhkferry@gmail.com" target="_blank" rel="noopener noreferrer">Share</a>{" "} your comments/feedback</p>
                    </div>
                </div>
            </div>
          
        );
    }
}

export default Footer;