import React, { Component } from 'react';
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Button} from 'reactstrap';
import ReactTooltip from 'react-tooltip';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
        };
        this.toggleNav = this.toggleNav.bind(this);
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    handleGoogleLogin(event) {
        this.props.googleLogin();
        event.preventDefault();
        this.toggleNav();
    }

    handleLogout() {
        this.props.logoutUser();
        this.toggleNav();
    }

    render() {
        return(
            <div>
                { (!this.props.typhoon_toggle) ?
                    <div></div>
                :
                    <div class="alert alert-danger mt-3 mb-0 py-0" role="alert">
                        <strong><a href="https://www.weather.gov.hk/en/wxinfo/currwx/tc.htm" target="_blank" rel="noopener noreferrer">A typhoon warning</a></strong> has been issued.  Schedules may be impacted.
                    </div>
                }
                <Navbar color="light" light expand="md" fixed="top">
                    <NavbarBrand href="/home"><img src="/images/ferry_180.png" height="40" width="40"
                                alt="myHKFerry" /><small className="text-muted"> My HK Ferry</small>
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggleNav} />
                    <Collapse isOpen={this.state.isNavOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem className="socialshare">
                                <div><a href="https://api.whatsapp.com/send?text=See upcoming ferry schedules: https://myhkferry.com" target="_blank" rel="noopener noreferrer"><img src="/images/whatsapp-share-button.png" alt="WhatsApp" className="btn_wa"/></a></div>
                            </NavItem>
                            <NavItem className="socialshare">
                                <div className="btn_tweet"><a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-show-count="false" data-text="See upcoming ferry schedules: " data-hashtags="myHKFerry">Share</a></div>
                            </NavItem>
                            <NavItem className="socialshare">
                                <div className="fb-share-button btn_fb" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button" data-size="small"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">Share</a></div>
                            </NavItem>
                        </Nav>
                        <Nav navbar>
                            
                            <NavItem>
                                { (!this.props.auth.isAuthenticated) ?
                                    <div className="pl-2 py-2">
                                        <Button color="secondary" size="sm" class="py-0 ml-0" onClick={this.handleGoogleLogin}>
                                        <span className="fab fa-google"></span><small data-tip="Login to save your favorites">&nbsp;&nbsp;Login with Google</small></Button>
                                        <ReactTooltip />
                                    </div>
                                    :
                                    <div>
                                        <div className="nav navbar-text mr-3">
                                            <img className="profile" src={this.props.auth.user.photoURL } height="40" width="40"
                                            alt={this.props.auth.user.displayName}/>&nbsp;
                                                {this.props.auth.user.displayName}
                                        </div>
                                        <Button color="secondary" size="sm" onClick={this.handleLogout}>
                                            <span className="fa fa-sign-out-alt"></span><small>&nbsp;&nbsp;Logout</small>
                                            {this.props.auth.isFetching ?
                                                <span className="fa fa-spinner fa-pulse fa-fw"></span>
                                                : null
                                            }
                                        </Button>
                                        </div>
                                }
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );    
    }            
}

export default Header;