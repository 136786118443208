import React from 'react';
import { Loading } from './LoadingComponent';
import {analytics} from '../firebase/firebase';
import RenderTableRow from './RenderTableRow'; 
import { Link } from 'react-router-dom';

const Favorites = (props) => {

    analytics.logEvent('Viewed Favorites'); 

    if (props.favorites.isLoading) {
        return(
            <div className="container">
                    <Loading />
            </div>
        );
    }
    else if (props.favorites.errMess) {
        return(
            <div className="container">
                <div className="row mt-5">
                    <h4>{props.favorites.errMess}</h4>
                </div>
            </div>
        )
    }
    else if (props.favorites.favorites && props.favorites.favorites.length !== 0) {
        const renderFavoriteFerries = props.favorites.favorites.map((favferry) => {
            for (var i=0; i< props.ferries.ferry.length; i++) {
                if (props.ferries.ferry[i]._id === favferry) {
                    if (props.ferries.ferry[i].nextFerry && props.ferries.ferry[i].isVisible) { //only show routes with returned ferry times and marked to visible in the DB
                        return (<RenderTableRow ferry={props.ferries.ferry[i]} lineBreak={true} isFavorite={true}/>)
                            
                    }
                }
            }
        })


        return(
            <div className="container py-4">
                    <div className="row pb-2">
                        <div className="col-12"><Link to='/allroutes'><button type="button" className="btn btn-outline-primary btn-sm">View All Routes</button></Link></div>
                    </div>

                    <div className="row pb-2">
                        <div className="col-12">
                            <p className="h7 pb-0 mb-0"><strong>Your favorite routes:</strong></p>
                        </div>
                    </div>
                   
                    <div className="row">
                    <div class="col-1 px-0"></div>
                    <div class="responsive col-2 px-0 text-center align-bottom">
                        <span class="py-0 my-0 font-weight-bold">From</span>
                    </div>
                    <div class="responsive col-2 px-0 text-center ">
                        <span class="py-0 my-0 font-weight-bold">To</span>
                    </div>
                    <div class="responsive col-2 px-0 text-center ">
                        <span class="py-0 my-0 font-weight-bold">Next Ferry</span>
                    </div>
                    <div class="responsive col-2 px-0 text-center ">
                        <span class="py-0 my-0 font-weight-bold">Leaves </span><span class="py-0 my-0">(mins)</span>
                    </div>
                    <div class="responsive col-2 px-0 text-center">
                        <span class="py-0 my-0 font-weight-bold">Following Ferry</span>
                    </div>
                    <div class="col-1 px-0"></div>
                </div>
                <div className="row">
                    <div className="col-12"> 
                        <hr class="py-0 my-0"/>
                    </div>
                </div>
                {renderFavoriteFerries}
            </div>
        );
    }
    else {
        return(
            <div className="container py-4">
                <div className="row">
                    <div className="col-12"><Link to='/allroutes'><button type="button" class="btn btn-outline-primary btn-sm">View All Routes</button></Link></div>
                </div>
                <div className="row">
                    <div class="col-1 px-0"></div>
                    <div class="responsive col-2 px-0 text-center align-bottom">
                        <span class="py-0 my-0 font-weight-bold">From</span>
                    </div>
                    <div class="responsive col-2 px-0 text-center ">
                        <span class="py-0 my-0 font-weight-bold">To</span>
                    </div>
                    <div class="responsive col-2 px-0 text-center ">
                        <span class="py-0 my-0 font-weight-bold">Next Ferry</span>
                    </div>
                    <div class="responsive col-2 px-0 text-center ">
                        <span class="py-0 my-0 font-weight-bold">Leaves </span><span class="py-0 my-0">(mins)</span>
                    </div>
                    <div class="responsive col-2 px-0 text-center">
                        <span class="py-0 my-0 font-weight-bold">Following Ferry</span>
                    </div>
                    <div class="col-1 px-0"></div>
                </div>
                <div className="row">
                    <div className="col-12"> 
                        <hr class="py-0 my-0"/>
                    </div>
                </div>
                <div class="row align-items-start" > 
                    <div class="col-12 py-1">
                        <p class="h6 text-danger">You have not selected any favorite ferry routes</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Favorites;