import { createStore, combineReducers, applyMiddleware } from 'redux';
import { favorites } from './favorites';
import { Auth } from './auth';
import { Ferries } from './ferries';
import { Config } from './config';

import thunk from 'redux-thunk';
import logger from 'redux-logger';

export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            ferries: Ferries,
            auth: Auth,
            favorites,
            config: Config
        }),
        applyMiddleware(thunk)
        //use applyMiddleware(thunk, logger) for state output to console
        //use applyMiddleware(thunk) for no output to console
    );

    return store;
}