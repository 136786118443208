export const FERRIES_LOADING = 'FERRIES_LOADING';
export const FERRIES_FAILED = 'FERRIES_FAILED';
export const ADD_FERRIES = 'ADD_FERRIES';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const FAVORITES_LOADING = 'FAVORITES_LOADING';
export const FAVORITES_FAILED = 'FAVORITES_FAILED';
export const ADD_FAVORITES = 'ADD_FAVORITES';
export const CONFIG_LOADING = 'CONFIG_LOADING';
export const CONFIG_FAILED = 'CONFIG_FAILED';
export const ADD_CONFIG = 'ADD_CONFIG';