import React, { Fragment } from 'react';

function RenderVideo({ferry}) {
        var youtube_url = ferry.youtube_url;
        var iframe = '';
            
        if (youtube_url) 
        iframe = <iframe title="Ferry Video" width="560" height="315" src={youtube_url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

        return(
            <div>
                <Fragment >
                {iframe}
                </Fragment>
            </div>
        )
}

export default RenderVideo;